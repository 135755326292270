.loginBody{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
    background-image: url(https://www.asus.com/support/images/deeplink/bg-top1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}form input{
    width:350px;
    height:40px;
    display: block;
    text-align: center;
    border:1px solid black;
    border-radius: 2rem;
    outline:none;
    background-color: #F4F6F6;
}form #Login_Button{
    width:350px;
    height:40px;
    outline:none;
    border:none;
    background-color: #1F618D;
    color:white;
}#Second{
    font-family: 'Acme', sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height:85vh;
}#First{
    font-family: 'Acme', sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height:85vh;
}
.loginHead::first-letter{
    font-size:60px;
}
.loginHead{
    color:white;
}#Contact a{
    font-size: 25px;
    padding:0px 10px 0px 10px;
}@media(max-width:400px){
    form input{
        width:300px;
    }form #Login_Button{
        width:300px;
    }
}@media(max-width:300px){
    form input{
        width:250px;
    }form #Login_Button{
        width:250px;
    }
}