.graph{
    display: flex;
    align-items: center;
    justify-content: center;

}
.Options a{
    font-size:15px;
    background-color: black;
    color:yellow;
    padding:5px 10px 5px 10px;
    border-radius: 1rem;
}
.Options a:hover{
    color:yellow;
}
.table{
    width: 90%;
}
.table td{
    word-wrap: break-word;
    
}

#Edit a{
    font-size:20px;
    text-decoration: none;
    background-color: black;
    padding:10px 10px 10px 10px;
    color:yellow;
    border-radius: 50%;
}
@media(max-width:500px){
    .piechart_3d{
        width: 500px;
        height:auto !important;
    }
    .table th{
        padding: 0.5rem;
    }
    .table td{
        padding: 0.5rem;
    }
}